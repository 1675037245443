import PropTypes from 'prop-types';
import React from 'react';

export default class Avatar extends React.Component {
    static proptypes = {
        avatar: PropTypes.string,
        displayName: PropTypes.string,
        initials: PropTypes.string.isRequired,
    };

    render() {
        const {avatar, displayName, initials} = this.props;
        // TODO remove the regex check once API is adjusted
        if (avatar && !avatar.match(/empty_avatar/)) {
            const alternativeDescription = displayName
                ? `${displayName} avatar`
                : 'Profile avatar';
            return (
                <img
                    alt={alternativeDescription}
                    className="avatar"
                    src={avatar}
                />
            );
        }
        return <span className="avatar">{initials}</span>;
    }
}

import React from 'react';
import PropTypes from 'prop-types';

import autobind from 'common/decorators/autobind.js';
import Chips from 'core/components/Chips.js';
import {HICAPS, TELEHEALTH} from 'core/constants';

export default class BadgeList extends React.Component {
    static propTypes = {
        isMedical: PropTypes.bool,
        offersHicaps: PropTypes.bool,
        offersTelehealth: PropTypes.bool,
    };

    static defaultProps = {
        isMedical: true,
        offersHicaps: false,
        offersTelehealth: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            badgeList: [],
        };
    }

    componentDidMount() {
        this.setBadgeList();
    }

    @autobind
    setBadgeList() {
        const {isMedical, offersHicaps, offersTelehealth} = this.props;
        const badgeList = [];
        if (offersHicaps) {
            badgeList.push({
                name: HICAPS,
                icon: 'hicapsIcon',
                content: (
                    <>
                        {`Offers instant rebates through `}
                        <strong>{HICAPS}</strong>
                    </>
                ),
                tooltip:
                    'Claim on-the-spot with HICAPS: use your health fund/Medicare card when paying for the service to receive your rebate instantly. You will only need to pay the difference between the service fee and the rebate.',
            });
        }
        if (offersTelehealth) {
            const teleHealthBadge = {
                customClass: 'telehealth',
                name: TELEHEALTH,
                icon: 'card',
                content: (
                    <>
                        Offers <strong>telehealth</strong> appointments
                    </>
                ),
                tooltip:
                    'This practitioner offers phone and/or video consultations.',
            };
            if (isMedical) {
                badgeList.unshift(teleHealthBadge);
            } else {
                badgeList.push(teleHealthBadge);
            }
        }
        this.setState({
            badgeList,
        });
    }

    render() {
        const {badgeList} = this.state;

        if (!badgeList.length) {
            return null;
        }
        return <Chips labels={badgeList} />;
    }
}

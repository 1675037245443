export default {
    get(name) {
        for (let c of document.cookie.split('; ')) {
            let [k, v] = c.split('=');
            if (k === name) {
                return decodeURIComponent(v);
            }
        }
    },

    set(name, value, options = {}) {
        let cookie = `${name}=${encodeURIComponent(value)}`;
        if (options.expires) {
            cookie += `; expires=${options.expires}`;
        }
        if (options.path) {
            cookie += `; path=${options.path}`;
        }
        document.cookie = cookie;
    },

    delete(name) {
        const value = this.get(name);
        if (!value) {
            return;
        }
        this.set(name, value, {expires: 'Thu, 01 Jan 1970 00:00:01 GMT'});
    },
};
